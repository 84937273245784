import React, { useEffect } from "react";
import UIDesign from '../../assets/ui-desing.webp';
import WebDesign from '../../assets/vscode.webp';
import AppDesign from '../../assets/ai-icon.webp';
import MediaDesign from '../../assets/Musiikki_media.webp';
import BackendDesign from '../../assets/backend.png';
import Tools from '../../assets/tools.png';
import SEO from '../../assets/seo.png';
import AOS from "aos";
import "aos/dist/aos.css";

const Skills = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  const skillsData = [
    {
      title: "UI/UX",
      description: "Figma, Draw.io, Canva, Adobe Photoshop",
      img: UIDesign,
      // link poistettu
    },
    {
      title: "Front-end kehitys",
      description: "HTML, CSS, JavaScript, React, Tailwind CSS, Bootstrap, WordPress, ASP.NET",
      img: WebDesign,
      // link poistettu
    },
    {
      title: "Back-end kehitys",
      description: "PHP, Firebase, MySQL, JSON, API-kehitys, Postman",
      img: BackendDesign,
      // link poistettu
    },
    {
      title: "Versionhallinta ja työkalut",
      description: "Git, GitHub, Visual Studio Code, Visual Studio, Slack",
      img: Tools,
      // link poistettu
    },
    {
      title: "Tekoäly",
      description: "ChatGPT, Google Gemini, Leonardo.ai, Adobe Firefly, Ideogram, Artflow",
      img: AppDesign,
      // link poistettu
    },
    {
      title: "SEO & Analytiikka",
      description: "Google Search Console, Google Analytics, Screaming Frog, hakukoneoptimointi (SEO), RankMath",
      img: SEO,
      // link poistettu
    },
    {
      title: "Musiikki & video",
      description: "Presonus Studio One, Suno, Adobe Premiere Pro, Corel VideoStudio",
      img: MediaDesign,
      // link poistettu
    },
  ];
  

  return (
    <div className="mt-40 light-mode dark:filter-none">
    <section id="skills" className="bg-white dark:bg-blue-900 text-black dark:text-white p-8">
      <h2 className="text-4xl font-bold text-center mb-8" title="Osaaminen">Osaaminen</h2>
      <p className="text-center mb-12 text-gray-700 dark:text-gray-300">
        Töissäni olen käyttänyt mm. näitä työkaluja ja ohjelmistoja:
      </p>
  
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {skillsData.map((skill, index) => (
          <div
            key={index}
            data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
            className="bg-gray-100 dark:bg-blue-800 p-6 rounded-xl dark:shadow-md hover:shadow-xl transition-transform transform hover:scale-105 border-2 border-transparent hover:border-blue-500 flex flex-col items-center text-center"
          >
            <img
              src={skill.img}
              alt={`${skill.title} icon`}
              className="w-20 h-20 mb-4 rounded-full shadow-lg"
              loading="lazy"
            />
            <h3 className="text-xl font-semibold mb-2" title={skill.title}>
              {skill.title}
            </h3>
            <p className="text-gray-600 dark:text-gray-300">
              {skill.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  </div>
  
  );
};

export default Skills;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaArrowDown } from "react-icons/fa"; // Tuodaan nuoli-ikoni

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,          // Perusanimaation kesto millisekunteina
      easing: "ease-out-cubic",// Pehmeä animaatio
      once: true,              // Animaatio käynnistyy vain kerran, kun elementti tulee näkyviin
    });
  }, []);

  return (
    <div className="mb-20">
      <section id="about" className="container mx-auto py-12 px-4">
        <div className="text-center">
          {/* Otsikko animoitu fade-down -efektillä */}
          <h2 className="text-3xl dark:text-white font-bold mb-6" data-aos="fade-down">
            Minusta
          </h2>
          {/* Kuvausteksti animoitu fade-up -efektillä */}
          <p
            className="text-black dark:text-white text-lg font-light  p-4 mx-auto"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Teknologia on minulle enemmän kuin työkalu – se on keino luoda, kehittää ja ratkaista ongelmia. Valmistuin Helsingin Taitotalosta tammikuussa 2025 ja olen kartuttanut kokemusta toteuttamalla verkkosivustoja, suunnittelemalla käyttöliittymiä sekä kehittämällä digitaalisia ratkaisuja, jotka sujuvoittavat arkea ja työtä.<br /><br />

            Jokainen projekti on ollut mahdollisuus oppia uutta ja kehittää taitojani entistä monipuolisemmiksi. Asiakastyöt ja omat projektit ovat vahvistaneet ymmärrystäni siitä, miten tekninen osaaminen ja sujuva käyttökokemus kulkevat käsi kädessä. Laadukas lopputulos ei synny pelkästään koodista – se vaatii myös ymmärrystä, luovuutta ja käyttäjälähtöistä ajattelua.<br /><br />

            Seuraavaksi voit tutustua IT-alani aikajanaani, josta ilmenevät urani merkittävimmät virstanpylväät.

          </p>
        </div>
    
        {/* Vilkkuva iso nuoli alaspäin – React Iconsilla */}
        <div className="mt-8 flex justify-center" data-aos="fade-up" data-aos-delay="600">
          <FaArrowDown className="w-12 h-12 text-black dark:text-white animate-blink" />
        </div>
      </section>
    </div>
  );
};

export default About;
